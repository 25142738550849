import { WppButton, WppTypography, WppIconReset, WppActionButton } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'

import { NewsFilters } from 'api/news/fetchers/fetchNewsApi'
import { Flex } from 'components/common/flex/Flex'
import { FormCountriesSelect } from 'components/form/formSelect/FormCountriesSelect'
import { FormCredentialsSelect } from 'components/form/formSelect/FormCredentialsSelect'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormSideModal } from 'components/surface/sideModal/FormSideModal'
import { useForm } from 'hooks/form/useForm'

interface Props {
  data: NewsFilters
  isOpen: boolean
  onClose: () => void
  onSubmit: (values: NewsFilters) => void
}

export enum NewsTypes {
  ALL = 'all',
  PRIMARY = 'primary',
  NON_PRIMARY = 'non-primary',
}

export const NewsListFilters = ({ data, onClose, isOpen, onSubmit }: Props) => {
  const NewsTypesOptions = [
    { value: NewsTypes.ALL, label: 'All' },
    { value: NewsTypes.PRIMARY, label: 'Primary' },
    { value: NewsTypes.NON_PRIMARY, label: 'Non-primary' },
  ]

  const form = useForm({
    defaultValues: data,
  })

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
    setValue,
  } = form

  const onModalClose = () => {
    onClose()
    setValue('tenantCredentialsIds', data.tenantCredentialsIds)
    setValue('countryAlpha2Codes', data.countryAlpha2Codes)
    setValue('primary', data.primary)
  }

  return (
    <FormProvider {...form}>
      <FormSideModal
        data-testid="news-filters-side-modal"
        open={isOpen}
        formProps={{
          onSubmit: handleSubmit(async values => {
            onSubmit(values)
            onClose()
          }),
          onReset: () => reset({ countryAlpha2Codes: [], tenantCredentialsIds: [], primary: NewsTypes.ALL }),
        }}
        size="m"
        onWppSideModalClose={onModalClose}
        disableOutsideClick
      >
        <WppTypography slot="header" type="2xl-heading">
          Filters
        </WppTypography>
        <Flex slot="body" direction="column" gap={24}>
          <FormCredentialsSelect
            type="multiple"
            name="tenantCredentialsIds"
            labelConfig={{ text: 'News source' }}
            placeholder="Select source"
            data-testid="credentials-select"
            required
          />
          <FormCountriesSelect
            type="multiple"
            name="countryAlpha2Codes"
            labelConfig={{ text: 'Country' }}
            placeholder="Select country"
            data-testid="country-select"
            required
          />
          <FormSelect
            required
            type="single"
            name="primary"
            options={NewsTypesOptions}
            labelConfig={{ text: 'Type' }}
            renderOptionContent={({ label }) => <span slot="label">{label}</span>}
            data-testid="primary-select"
          />
        </Flex>
        <Flex slot="actions" justify="between" align="center">
          <WppActionButton type="reset" data-testid="reset-button">
            <WppIconReset slot="icon-start" />
            Reset
          </WppActionButton>
          <Flex gap={12}>
            <WppButton
              variant="secondary"
              size="m"
              type="button"
              data-testid="cancel-button"
              onClick={() => {
                onModalClose()
              }}
            >
              Cancel
            </WppButton>
            <WppButton variant="primary" size="m" type="submit" loading={isSubmitting} data-testid="save-button">
              Save
            </WppButton>
          </Flex>
        </Flex>
      </FormSideModal>
    </FormProvider>
  )
}
