import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import { useAsyncFn } from 'react-use'

import { useUpdateNewsItemApi } from 'api/news/mutations/useUpdateNewsItemApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'providers/toast/ToastProvider'

export const useNewsActivationDeactivation = () => {
  const { osContext } = useOs()
  const { tenant } = osContext
  const queryClient = useQueryClient()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateNewsItem } = useUpdateNewsItemApi()

  const [{ loading: isLoading }, handleActivationDeactivation] = useAsyncFn(
    async (guid: string, hidden: boolean) => {
      try {
        await handleUpdateNewsItem({
          tenantId: tenant.azMeta.organizationsId,
          newsId: guid || '',
          newsItem: {
            hidden: !hidden,
          },
        })

        await queryClient.invalidateQueries([ApiQueryKeys.NEWS])

        enqueueToast({
          message: `News item is ${hidden ? 'activated' : 'deactivated'} successfully`,
          type: 'success',
        })
      } catch (e) {
        enqueueToast({
          message: 'Something went wrong, please try again',
          type: 'error',
        })
      }
    },
    [handleUpdateNewsItem, tenant, queryClient, enqueueToast],
  )

  return {
    handleActivationDeactivation,
    isLoading,
  }
}
