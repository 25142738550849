import * as zod from 'zod'

import { News, NewsItemForm, NewsItemUpdateDTO } from 'types/news/news'

export const defaultValues: NewsItemForm = {
  countryAlpha2Codes: [],
  isPrimary: false,
  isActive: false,
}

export const mapNewsItemToForm = (data: News): NewsItemForm => ({
  countryAlpha2Codes: data.country_alpha2_codes || [],
  isPrimary: data.primary,
  isActive: !data.hidden,
})

export const mapFormToNewsItem = (data: NewsItemForm): NewsItemUpdateDTO => ({
  country_alpha2_codes: data.countryAlpha2Codes,
  primary: data.isPrimary,
  hidden: !data.isActive,
})

export const validationSchema = zod.object({
  countryAlpha2Codes: zod.string().array(),
  isPrimary: zod.boolean(),
  isActive: zod.boolean(),
})
