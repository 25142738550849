import {
  WppCard,
  WppTypography,
  WppMenuContext,
  WppActionButton,
  WppIconMore,
  WppIconRemoveCircle,
  WppIconAddCircle,
  WppListItem,
  WppSpinner,
  WppIconEdit,
} from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { ImagePlaceholder } from 'components/imagePlaceholder/imagePlaceholder'
import styles from 'components/newsCard/NewsCard.module.scss'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermissions'
import { useNewsActivationDeactivation } from 'hooks/useNewsActivationDeactivation'
import { News } from 'types/news/news'
import { formatDate } from 'utils/dates'

interface Props {
  newsItem: News
  onEdit: () => void
  locale: string
}

export const NewsCard = ({ newsItem, locale, onEdit }: Props) => {
  const { banner_image, authors, title, created_at: date, guid, hidden } = newsItem
  const { hasPermission } = useHasPermission()
  const hasAccessToNewsSettings = hasPermission(Permission.NewsSettingsManage)
  const { handleActivationDeactivation, isLoading } = useNewsActivationDeactivation()
  return (
    <WppCard interactive className={styles.card}>
      {isLoading ? (
        <Flex align="center" justify="center" style={{ height: '100%' }}>
          <WppSpinner size="m" />
        </Flex>
      ) : (
        <>
          {hasAccessToNewsSettings && (
            <WppMenuContext
              slot="actions"
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
              dropdownConfig={{
                appendTo: () => document.body,
                placement: 'bottom-end',
              }}
            >
              <WppActionButton
                variant="secondary"
                style={{ zIndex: 5 }}
                slot="trigger-element"
                className={styles.whiteActionButton}
              >
                <WppIconMore direction="horizontal" />
              </WppActionButton>
              <div>
                <WppListItem onWppChangeListItem={() => onEdit()}>
                  <WppIconEdit slot="left" />
                  <span slot="label">Edit</span>
                </WppListItem>
                <WppListItem
                  onWppChangeListItem={() => {
                    handleActivationDeactivation(guid, hidden)
                  }}
                >
                  {hidden ? <WppIconAddCircle slot="left" /> : <WppIconRemoveCircle slot="left" />}
                  <span slot="label">{hidden ? 'Activate' : 'Deactivate'}</span>
                </WppListItem>
              </div>
            </WppMenuContext>
          )}

          {banner_image ? <img className={styles.cover} src={banner_image} alt="" /> : <ImagePlaceholder />}
          <div className={styles.content}>
            <div className={styles.author}>
              <WppTypography type="s-midi">{authors.join(', ')}</WppTypography>
            </div>
            <div>
              <WppTypography type="m-strong" data-testid="news-title">
                {title}
              </WppTypography>
            </div>
            <div className={styles.footer}>
              <WppTypography className={styles.date} type="xs-body" data-testid="date-label">
                {formatDate(date, locale)}
              </WppTypography>
            </div>
          </div>
        </>
      )}
    </WppCard>
  )
}
