import {
  FormSelect,
  FormSelectOption,
  FormSelectSkeleton,
  FormSelectProps,
} from 'components/form/formSelect/FormSelect'
import { useCredentials } from 'hooks/useCredentials'

type Props = Omit<FormSelectProps<FormSelectOption>, 'options'>

export const FormCredentialsSelect = ({
  required,
  type = 'single',
  placeholder,
  name,
  labelConfig,
  'data-testid': dataTestid,
}: Props) => {
  const { credentialOptions, isLoading } = useCredentials()

  return (
    <>
      {isLoading ? (
        <FormSelectSkeleton />
      ) : (
        <FormSelect
          required={required}
          withSearch
          type={type}
          placeholder={placeholder}
          name={name}
          options={credentialOptions}
          labelConfig={labelConfig}
          renderOptionContent={({ label }) => <span slot="label">{label}</span>}
          data-testid={dataTestid}
        />
      )}
    </>
  )
}
