import {
  WppGrid,
  WppTypography,
  WppSpinner,
  WppButton,
  WppIconGear,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppInput,
  WppFilterButton,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType, MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { useSetState } from 'react-use'

import { NewsFilters } from 'api/news/fetchers/fetchNewsApi'
import { useNewsApi } from 'api/news/queries/useNewsApi'
import { Flex } from 'components/common/flex/Flex'
import { EditNewsItem } from 'components/editNewsItem/EditNewsItem'
import { EmptyState } from 'components/emptyState/EmptyState'
import { NewsCard } from 'components/newsCard/NewsCard'
import { NewsListFilters, NewsTypes } from 'components/newsListFilters/NewsListFilters'
import { Delay } from 'constants/delay'
import { Permission } from 'constants/permission'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useHasPermission } from 'hooks/useHasPermissions'
import styles from 'pages/newsList/NewsList.module.scss'
import { News } from 'types/news/news'
import { getObjectValuesCount } from 'utils/common'
import { EVENTS } from 'utils/events'

enum TABS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

interface State {
  isEditNewsItemModalOpen: boolean
  isNewsFiltersModalOpen: boolean
  newsItem: MayBeNull<News>
  selectedTab: TABS
  search: string
  filters: NewsFilters
}

export const NewsList = () => {
  const { osContext, osApi } = useOs()
  const { tenant } = osContext
  const { hasPermission } = useHasPermission()
  const hasAccessToNewsSettings = hasPermission(Permission.NewsSettingsManage)

  const defaultFilterValues = {
    search: '',
    filters: {
      countryAlpha2Codes: [],
      tenantCredentialsIds: [],
      primary: NewsTypes.ALL,
    },
  }

  const [{ isEditNewsItemModalOpen, newsItem, selectedTab, isNewsFiltersModalOpen, filters, search }, setState] =
    useSetState<State>({
      isEditNewsItemModalOpen: false,
      isNewsFiltersModalOpen: false,
      newsItem: null,
      selectedTab: TABS.ACTIVE,
      ...defaultFilterValues,
    })

  const { isLoading, data: news } = useNewsApi({
    params: {
      tenantId: tenant.azMeta.organizationsId,
      hidden: selectedTab !== TABS.ACTIVE,
      search,
      filters,
    },
  })

  const setSearchDebounced = useDebounceFn(
    (search?: string) => setState({ search: search?.trim() || '' }),
    Delay.Search,
  )

  const removePrimaryFilterIfNotApplied = (filters: NewsFilters) => {
    const { primary, ...rest } = filters
    return {
      ...rest,
      ...(primary !== NewsTypes.ALL && { primary }),
    }
  }

  const NewsCardWrapper = ({
    openInsideOs,
    children,
    guid,
    postUrl,
  }: {
    openInsideOs: boolean
    children: ReactNode
    guid: string
    postUrl: string
  }): JSX.Element =>
    openInsideOs ? (
      <Link to={`/${guid}`}>{children}</Link>
    ) : (
      <a href={postUrl} target="_blank" rel="noreferrer">
        {children}
      </a>
    )

  const content = (isLoading: boolean, news: News[]) => {
    if (isLoading) {
      return (
        <Flex align="center" justify="center" style={{ height: '100%' }}>
          <WppSpinner size="m" />
        </Flex>
      )
    } else if (!news.length) {
      return <EmptyState title="No news found" className={styles.emptyState} />
    } else {
      return (
        <WppGrid container fullHeight fullWidth all={24} data-testid="news-grid">
          {news.map(newsItem => (
            <WppGrid item all={6} key={newsItem.guid}>
              <NewsCardWrapper openInsideOs={newsItem.open_inside_os} guid={newsItem.guid} postUrl={newsItem.post_url}>
                <NewsCard
                  newsItem={newsItem}
                  locale={osContext.userDetails.dateLocale}
                  onEdit={() => setState({ isEditNewsItemModalOpen: true, newsItem })}
                />
              </NewsCardWrapper>
            </WppGrid>
          ))}
        </WppGrid>
      )
    }
  }

  const displaySearchAndFilters =
    !!news.length || search || !!getObjectValuesCount(removePrimaryFilterIfNotApplied(filters))

  return (
    <>
      <EditNewsItem
        data={newsItem}
        isOpen={isEditNewsItemModalOpen}
        onClose={() => setState({ isEditNewsItemModalOpen: false })}
      />
      <NewsListFilters
        data={filters}
        isOpen={isNewsFiltersModalOpen}
        onClose={() => setState({ isNewsFiltersModalOpen: false })}
        onSubmit={filters => setState({ filters })}
      />
      <Flex className={styles.header} gap={16} direction="column">
        <Flex justify="between">
          <WppTypography type="3xl-heading" data-testid="news-page-title">
            News
          </WppTypography>
          {hasAccessToNewsSettings ? (
            <Link
              to="/settings"
              onClick={() => {
                osApi.analytics.track({
                  type: AnalyticsActionType.action,
                  payload: EVENTS.ACTIONS.SETTINGS,
                })
                osApi.analytics.track({
                  type: AnalyticsActionType.page,
                  payload: EVENTS.PAGE.SETTINGS,
                })
              }}
            >
              <WppButton variant="secondary">
                <WppIconGear slot="icon-start" />
                Settings
              </WppButton>
            </Link>
          ) : null}
        </Flex>
        {hasAccessToNewsSettings && (
          <Flex justify={displaySearchAndFilters ? 'between' : 'end'}>
            {displaySearchAndFilters && (
              <Flex gap={16}>
                <WppInput
                  size="s"
                  type="search"
                  onWppChange={({ detail }) => setSearchDebounced(detail.value)}
                  placeholder="Search news by title"
                />
                <WppFilterButton
                  onClick={() => setState({ isNewsFiltersModalOpen: true })}
                  counter={getObjectValuesCount(removePrimaryFilterIfNotApplied(filters))}
                >
                  Filters
                </WppFilterButton>
              </Flex>
            )}
            <WppSegmentedControl
              value={selectedTab}
              size="s"
              onWppChange={({ detail: { value } }) => {
                value && setState({ selectedTab: value as TABS, ...defaultFilterValues })
              }}
            >
              <WppSegmentedControlItem value={TABS.ACTIVE}>Active</WppSegmentedControlItem>
              <WppSegmentedControlItem value={TABS.INACTIVE}>Inactive</WppSegmentedControlItem>
            </WppSegmentedControl>
          </Flex>
        )}
      </Flex>

      {content(isLoading, news)}
    </>
  )
}
