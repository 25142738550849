import qs from 'qs'

import { facadeApi } from 'api'
import { NewsTypes } from 'components/newsListFilters/NewsListFilters'
import { News } from 'types/news/news'

export interface NewsFilters {
  countryAlpha2Codes?: string[]
  tenantCredentialsIds?: string[]
  primary: NewsTypes
}

export interface Params {
  tenantId: string
  hidden: boolean
  search: string
  filters: NewsFilters
}

export const fetchNewsApi = ({
  tenantId,
  hidden,
  filters: { countryAlpha2Codes, tenantCredentialsIds, primary },
  search,
}: Params) =>
  facadeApi.get<News[]>('/news', {
    params: {
      tenant_id: tenantId,
      filter: {
        hidden,
        ...(!!search && { search }),
        ...(!!countryAlpha2Codes?.length && { countryAlpha2Codes }),
        ...(!!tenantCredentialsIds?.length && { tenantCredentialsIds }),
        ...(!!countryAlpha2Codes?.length && { includeNoCountries: false }), //Need to be fixed, Max is aware
        ...(primary !== NewsTypes.ALL && { primary: primary === NewsTypes.PRIMARY }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
